



























































































































































































































































































































































































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  alphaNum,
  maxLength,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
  maxValue,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/PublicVoucherController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import VoucherTextInput from '../../components/VoucherTextInput/index.vue'
import VoucherDropdown from '../../components/VoucherDropdown/index.vue'
import VoucherDatePicker from '../../components/VoucherDatePicker/index.vue'
import * as constantData from '@/app/infrastructures/misc/Constants/publicVoucher'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import UpdateAppVersionDropdown from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionDropdown/index.vue'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import { Utils } from '@/app/infrastructures/misc'
import { PublicVoucherCodeMultipleExternalPartner } from '@/domain/entities/PublicVoucher'
import { IFormCreatePublicVoucher } from '@/data/infrastructures/misc/interfaces/publicVoucher';

interface validationInterface {
  form: {
    voucherName: {
      required: () => ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    }
    voucherCode: {
      required: () => boolean
      minLength: ValidationRule
      alphaNum: () => ValidationRule
    }
    voucherLimit: {
      required: () => ValidationRule
      numeric: () => ValidationRule
      minValue: ValidationRule
    }
    voucherPerUser: {
      required: () => ValidationRule
      numeric: () => ValidationRule
      minValue: ValidationRule
    }
    voucherPurpose: {
      required: () => ValidationRule
    }
    voucherPointAmount: {
      required: () => boolean
      numeric: () => ValidationRule
      minValue: ValidationRule
    }
    discountPercentage: {
      required: () => boolean
      minValue: ValidationRule
      maxValue: ((validation: unknown) => boolean)
    }
    maxDiscountAmount: {
      required: () => boolean
      minValue: ValidationRule
    }
    minTransaction: {
      required: () => boolean
      minValue: ValidationRule
    }
    shipmentTypes: {
      required: () => boolean
      minLength: ValidationRule
    }
    redeemStartDate: {
      required: () => ValidationRule
    }
    redeemEndDate: {
      required: () => ValidationRule
    }
    voucherExpiryDate: {
      required: () => boolean
    }
    redeemStartTime: {
      required: () => ValidationRule
    }
    redeemEndTime: {
      required: () => ValidationRule
    }
    voucherCodeMultipleCount: {
      required: () => boolean
      minValue: ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    UpdateAppVersionDropdown,
    Button,
    Modal,
    RadioButton,
    ModalAction,
    VoucherTextInput,
    VoucherDropdown,
    VoucherDatePicker,
    OptionBox,
    LoadingOverlay,
    DateTimePickerV2,
    TimePicker,
  },
})
export default class CreatePublicVoucher extends Vue {
  controller = controller
  constantData = constantData
  maxCount = 30
  multipleVoucherCodePartner: DropDownLabelValue<string>[] = []
  form: IFormCreatePublicVoucher = {
    voucherName: '',
    voucherCode: '',
    voucherLimit: 1,
    voucherPerUser: 1,
    voucherPurpose: undefined,
    voucherPointAmount: 0,
    discountPercentage: 1,
    maxDiscountAmount: 1,
    minTransaction: 1,
    shipmentTypes: [],
    voucherExpiry: 1,
    redeemStartDate: undefined,
    redeemEndDate: undefined,
    voucherExpiryDate: undefined,
    redeemStartTime: undefined,
    redeemEndTime: undefined,
    voucherCodeType: constantData.EnumVoucherCodeType.SINGLE,
    voucherCodeMultipleType: constantData.EnumVoucherCodeMultipleType.INTERNAL,
    voucherCodeMultipleCount: 1,
    voucherCodeMultiplePartner: [],
  }
  successModal = false
  confirmationModal = false
  todayDate = dayjs().format('YYYY-MM-DD')
  hasChanged = {
    voucherName: false,
    voucherCode: false,
    voucherLimit: false,
    voucherPerUser: false,
    voucherPurpose: false,
    voucherPointAmount: false,
    discountPercentage: false,
    maxDiscountAmount: false,
    minTransaction: false,
    shipmentTypes: false,
    voucherExpiry: false,
    redeemStartDate: false,
    redeemEndDate: false,
    voucherExpiryDate: false,
    redeemStartTime: false,
    redeemEndTime: false,
    multipleVoucherCount: false,
  }

  @Validations()
  validations(): validationInterface {
    return {
      form: {
        voucherName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(this.maxCount),
        },
        voucherCode: {
          required: requiredIf(() => !this.isMultipleVoucher),
          minLength: minLength(!this.isMultipleVoucher ? 3 : 0),
          alphaNum
        },
        voucherLimit: {required, numeric, minValue: minValue(1)},
        voucherPerUser: {required, numeric, minValue: minValue(1)},
        voucherPurpose: {required},
        voucherPointAmount: {
          required: requiredIf(() => this.form.voucherPurpose?.value === constantData.EnumVoucherPurpose.PARCEL_POINT),
          numeric,
          minValue: minValue(this.form.voucherPurpose?.value === constantData.EnumVoucherPurpose.PARCEL_POINT ? 1 : 0)
        },
        discountPercentage: {
          required: requiredIf(() => this.isDiscountPercentage),
          minValue: minValue(this.isDiscountPercentage ? 1 : 0),
          maxValue: (validation: unknown) => {
            if (this.isDiscountPercentage) {
              return maxValue(100)(validation)
            }
            return true
          },
        },
        maxDiscountAmount: {
          required: requiredIf(() => this.isDiscountPercentage),
          minValue: minValue(this.isDiscountPercentage ? 1 : 0),
        },
        minTransaction: {
          required: requiredIf(() => this.isDiscountPercentage),
          minValue: minValue(this.isDiscountPercentage ? 1 : 0),
        },
        shipmentTypes: {
          required: requiredIf(() => this.isDiscountPercentage),
          minLength: minLength(this.isDiscountPercentage ? 1 : 0),
        },
        redeemStartDate: {required},
        redeemEndDate: {required},
        voucherExpiryDate: {
          required: requiredIf(() => this.isDiscountPercentage),
        },
        redeemStartTime: {required},
        redeemEndTime: {required},
        voucherCodeMultipleCount: {
          required: requiredIf(() => this.isMultipleVoucher),
          minValue: minValue(this.isMultipleVoucher ? 1 : 0),
        }
      },
    }
  }

  created(): void {
    controller.getVoucherCodeMultipleExternalPartner()
  }

  private setAllChanged(): void {
    this.hasChanged = {
      voucherName: true,
      voucherCode: true,
      voucherLimit: true,
      voucherPerUser: true,
      voucherPurpose: true,
      voucherPointAmount: true,
      discountPercentage: true,
      maxDiscountAmount: true,
      minTransaction: true,
      shipmentTypes: true,
      voucherExpiry: true,
      redeemStartDate: true,
      redeemEndDate: true,
      voucherExpiryDate: true,
      redeemStartTime: true,
      redeemEndTime: true,
      multipleVoucherCount: true,
    }
  }

  private onCreateVoucher(): void {
    this.setAllChanged()
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      const startDateTime = Utils.setFormatDateTime(
        <Date>this.form.redeemStartDate,
        (<Date>this.form.redeemStartTime).getHours(),
        (<Date>this.form.redeemStartTime).getMinutes()
      )
      const endDateTime = Utils.setFormatDateTime(
        <Date>this.form.redeemEndDate,
        (<Date>this.form.redeemEndTime).getHours(),
        (<Date>this.form.redeemEndTime).getMinutes()
      )
      const expiryDateTime = !this.isDiscountPercentage ? '' : Utils.setFormatDateTime(
        <Date>this.form.voucherExpiryDate,
        23,
        59,
        59
      )

      const formatDateTime = 'YYYY-MM-DDTHH:mm:ssZ'

      let partnerCode: string | undefined = this.form.voucherCodeMultiplePartner[0].value
      let multipleCount = this.form.voucherCodeMultipleCount
      if (this.form.voucherCodeType !== constantData.EnumVoucherCodeType.MULTIPLE) {
        multipleCount = undefined
        partnerCode = undefined
      }

      if (this.form.voucherCodeMultipleType === constantData.EnumVoucherCodeMultipleType.INTERNAL) {
        partnerCode = ''
      }

      controller.createPublicVoucher({
        voucherName: this.form.voucherName,
        voucherCode: this.form.voucherCode,
        voucherLimit: this.form.voucherLimit,
        voucherPerUser: this.form.voucherPerUser,
        voucherPurpose: this.form.voucherPurpose,
        voucherPointAmount: this.form.voucherPointAmount,
        discountPercentage: this.form.discountPercentage,
        maxDiscountAmount: this.form.maxDiscountAmount,
        minTransaction: this.form.minTransaction,
        shipmentTypes: this.form.shipmentTypes,
        voucherExpiry: this.form.voucherExpiry,
        redeemStartDateTime: Utils.formatDate(startDateTime, formatDateTime),
        redeemEndDateTime: Utils.formatDate(endDateTime, formatDateTime),
        voucherExpiryDateTime: Utils.formatDate(expiryDateTime, formatDateTime, ''),
        voucherPartnerCode: partnerCode,
        multipleVoucherCount: multipleCount,
      })
    } else {
      this.$notify({
        title: 'Invalid Create Public Voucher',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  get titleDateTime(): string {
    return this.isDiscountPercentage || !this.form.voucherPurpose ? 'Redeem' : ''
  }

  get isDiscountPercentage(): boolean {
    return this.form.voucherPurpose?.value === constantData.EnumVoucherPurpose.DISCOUNT_PERCENTAGE
  }

  get isMultipleVoucher(): boolean {
    return this.form.voucherCodeType === constantData.EnumVoucherCodeType.MULTIPLE
  }

  private onRedeemStartDateChange(date: Date): void {
    this.form.redeemStartDate = date
    this.hasChanged.redeemStartDate = true
  }

  private onRedeemStartTimeChange(date: Date): void {
    this.form.redeemStartTime = date
    this.hasChanged.redeemStartTime = true
  }

  private onRedeemEndDateChange(date: Date): void {
    this.form.redeemEndDate = date
    this.hasChanged.redeemEndDate = true
  }

  private onRedeemEndTimeChange(date: Date): void {
    this.form.redeemEndTime = date
    this.hasChanged.redeemEndTime = true
  }

  private onVoucherExpireChange(date: Date): void {
    this.form.voucherExpiryDate = date
    this.hasChanged.voucherExpiryDate = true
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({name: 'PublicVoucherPage'})
  }

  private setShipmentTypes(value: string): void {
    if (this.form.shipmentTypes.includes(value)) {
      this.form.shipmentTypes = this.form.shipmentTypes.filter(item => item !== value)
    } else {
      this.form.shipmentTypes.push(value)
    }
  }

  @Watch('controller.isSuccessSave')
  private onIsSuccessSaveChange(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  @Watch('controller.publicVoucherCodePartner')
  private onPublicVoucherListPartner(data: PublicVoucherCodeMultipleExternalPartner[]): void {
    if (data.length > 0) {
      this.multipleVoucherCodePartner = data.map(v => {
        return {
          label: Utils.toCapitalize(v.partnerName),
          value: v.partnerCode
        }
      })
      this.form.voucherCodeMultiplePartner = [this.multipleVoucherCodePartner[0]]
    }
  }

  get hourOptions(): Array<string | number> {
    return Array.from({length: 24}, (_x, i) => i)
  }

  beforeDestroy(): void {
    controller.setSuccessSave(false)
  }
}
